import React, { useState, useEffect, useCallback } from 'react';

const TiersEditor = ({ tiers, onChange }) => {
  const [bulkText, setBulkText] = useState('');

  useEffect(() => {
    const text = tiers.map(tier => `${tier.max}\t${(tier.rate * 100).toFixed(8)}`).join('\n');
    setBulkText(text);
  }, [tiers]);

  const handleBulkChange = useCallback((e) => {
    const newText = e.target.value;
    setBulkText(newText);
  }, []);

  const updateTiers = useCallback(() => {
    const newTiers = bulkText.split('\n').map(line => {
      const [max, rate] = line.split('\t');
      return {
        max: parseFloat(max),
        rate: parseFloat(rate) / 100
      };
    }).filter(tier => !isNaN(tier.max) && !isNaN(tier.rate));

    onChange(newTiers);
  }, [bulkText, onChange]);

  return (
    <div className="mt-4">
      <textarea
        className="w-full h-32 font-mono text-sm p-2 border rounded resize-y"
        value={bulkText}
        onChange={handleBulkChange}
        placeholder="Enter tiers (one per line, format: max_value tab rate_percentage)"
      />
      <div className="mt-2 flex flex-col sm:flex-row sm:justify-between sm:items-center">
        <button
          onClick={updateTiers}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors mb-2 sm:mb-0"
        >
          Save Rate Schedule
        </button>
        <p className="text-sm text-gray-600">
          <strong>Format:</strong> One tier per line. Max value, tab, rate percentage (e.g., "10000 2.5" for $10,000 at 2.5%)
        </p>
      </div>
    </div>
  );
};

export default TiersEditor;
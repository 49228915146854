import React from 'react';
import { BrowserRouter as Router, Route, NavLink, Routes } from 'react-router-dom';
import YearBasedPricingCalculator from './components/PricingCalculator';
import TierGenerator from './components/TierGenerator';
import Comparisons from './components/Comparisons';
import { useState } from 'react';
import { FaLock, FaUnlock } from 'react-icons/fa';

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState('');

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    // Replace 'admin123' with your desired password
    if (password === '$100kMRR') {
      setIsAdmin(true);
      setShowPasswordModal(false);
    } else {
      alert('Incorrect password');
    }
  };

  const handleLogout = () => {
    setIsAdmin(false);
    setPassword('');
  };

  return (
    <Router>
      <div className="App">
        <nav className="bg-gray-800 p-4">
          <div className="max-w-7xl mx-auto">
            <div className="flex justify-between items-center">
              <div className="flex-shrink-0">
                <span className="text-white text-lg font-semibold">Cerebro Analytics Pricing Tools</span>
              </div>
              <div className="flex space-x-4 items-center">
                <NavLink 
                  to="/" 
                  className={({ isActive }) => 
                    isActive ? "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium" 
                             : "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  }
                  end
                >
                  Calculator
                </NavLink>
                <NavLink 
                  to="/compare-scenarios" 
                  className={({ isActive }) => 
                    isActive ? "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium" 
                             : "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  }
                >
                  Comparisons
                </NavLink>
                {isAdmin && (
                  <>
                    <NavLink 
                      to="/build-new-rate-schedule" 
                      className={({ isActive }) => 
                        isActive ? "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium" 
                                 : "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      }
                    >
                      Rate Schedule Builder
                    </NavLink>
                    <NavLink 
                      to="https://eyeballdivisionllc-cerebro-pricing--apprevenue-generator-sppsyx.streamlit.app/" 
                      className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Monthly Revenue Generator
                    </NavLink>
                  </>
                )}
                <button
                  onClick={isAdmin ? handleLogout : () => setShowPasswordModal(true)}
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  {isAdmin ? <FaUnlock /> : <FaLock />}
                </button>
              </div>
            </div>
          </div>
        </nav>

        <div className="max-w-7xl mx-auto mt-8 px-4">
          <Routes>
            <Route path="/" element={<YearBasedPricingCalculator isAdmin={isAdmin} />} />
            <Route path="/compare-scenarios" element={<Comparisons />} />
            {isAdmin && <Route path="/build-new-rate-schedule" element={<TierGenerator />} />}
          </Routes>
        </div>

        {showPasswordModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="bg-white p-5 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">Enter Admin Password</h2>
              <form onSubmit={handlePasswordSubmit}>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border rounded px-3 py-2 mb-4 w-full"
                  placeholder="Password"
                />
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={() => setShowPasswordModal(false)}
                    className="bg-gray-300 text-gray-800 px-4 py-2 rounded mr-2"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-600 text-white px-4 py-2 rounded"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const chartColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const normalizeRate = (rate) => {
  if (rate > 1) {
    return rate / 100; // Convert percentage to decimal
  }
  return rate;
};

function Comparisons() {
  const [scenarios, setScenarios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeChart, setActiveChart] = useState('period');
  const [timeAggregation, setTimeAggregation] = useState('month');

  const loadScenarios = useCallback((event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      setIsLoading(true);
      const loadPromises = files.map(file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const scenarioData = JSON.parse(e.target.result);
            resolve({ name: file.name, data: scenarioData });
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = reject;
        reader.readAsText(file);
      }));

      Promise.all(loadPromises)
        .then(newScenarios => {
          setScenarios(prevScenarios => [...prevScenarios, ...newScenarios]);
          console.log('All scenarios loaded:', newScenarios);
        })
        .catch(error => {
          console.error('Error loading scenarios:', error);
          alert('Error loading one or more scenarios. Please check the files and try again.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const removeScenario = useCallback((index) => {
    setScenarios(prevScenarios => prevScenarios.filter((_, i) => i !== index));
  }, []);

  const formatNumberWithCommas = useCallback((number) => {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }, []);

  const formatYAxis = useCallback((value) => {
    if (typeof value !== 'number' || isNaN(value)) {
      console.warn('Invalid value for Y-axis:', value);
      return '';
    }
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(1)}K`;
    } else {
      return `$${value.toFixed(0)}`;
    }
  }, []);

  const formatXAxis = useCallback((value) => {
    return `$${(value).toFixed(1)}M`;
  }, []);

  const aggregateData = useCallback((data, aggregationType) => {
    console.log('Aggregating data:', data, 'Type:', aggregationType);
    const aggregatedData = {};
    
    data.forEach(item => {
      if (!item.startDate || isNaN(item.startDate)) {
        console.warn('Invalid date encountered:', item.startDate);
        return;
      }

      let key;
      const date = new Date(item.startDate);
      switch (aggregationType) {
        case 'month':
          key = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)).getTime();
          break;
        case 'year':
          key = new Date(Date.UTC(date.getUTCFullYear(), 0, 1)).getTime();
          break;
        default:
          key = item.startDate;
      }
      
      if (!aggregatedData[key]) {
        aggregatedData[key] = { date: key, fee: 0 };
      }
      aggregatedData[key].fee += item.fee;
    });
    
    const result = Object.values(aggregatedData).sort((a, b) => a.date - b.date);
    console.log('Aggregated data:', result);
    return result;
  }, []);

  const formatPercentage = (value) => {
    return `${(value * 100).toFixed(2)}%`;
  };

  const getChartData = useCallback((scenario, chartType) => {
    console.log(`Processing ${scenario.name} for ${chartType}`);
    switch (chartType) {
      case 'period':
        return scenario.data.transactions
          .map(t => ({
            period: new Date(t.startDate).getTime(),
            rate: normalizeRate(t.rate), // Ensure this is using the normalized rate
            name: scenario.name
          }))
          .sort((a, b) => a.period - b.period);
      case 'cumulativeRate':
        const data = scenario.data.transactions.reduce((acc, t, index, arr) => {
          const cumulativeRevenue = arr.slice(0, index + 1).reduce((sum, tx) => sum + tx.amount, 0) / 1000000;
          const normalizedRate = normalizeRate(t.rate);
          const rate = normalizedRate * 100; // Convert to percentage for display
          console.log(`${scenario.name}: Revenue: ${cumulativeRevenue}, Original Rate: ${t.rate}, Normalized Rate: ${normalizedRate}, Display Rate: ${rate}`);
          acc.push({
            cumulativeRevenue,
            rate,
            name: scenario.name
          });
          return acc;
        }, []);
        console.log(`Chart data for ${scenario.name}:`, data);
        return data;
      case 'cumulativeFee':
        return scenario.data.transactions.reduce((acc, t, index) => {
          const lastEntry = acc[acc.length - 1] || { cumulativeRevenue: 0, cumulativeFee: 0 };
          const newRevenue = typeof t.revenue === 'number' ? t.revenue : 0;
          const newFee = typeof t.fee === 'number' ? t.fee : 0;
          acc.push({
            cumulativeRevenue: (lastEntry.cumulativeRevenue + newRevenue) / 1000000, // Convert to millions
            cumulativeFee: lastEntry.cumulativeFee + newFee,
            name: scenario.name
          });
          return acc;
        }, []);
      case 'timeSeries':
        const rawData = scenario.data.transactions.map(t => {
          const date = new Date(t.startDate);
          return {
            startDate: date.getTime(), // Use the original timestamp
            fee: t.fee,
          };
        }).filter(item => !isNaN(item.startDate));
        console.log('Processed raw data:', rawData);
        return aggregateData(rawData, timeAggregation);
      default:
        return [];
    }
  }, [timeAggregation, aggregateData]);

  const combinedChartData = useMemo(() => {
    if (scenarios.length === 0 || isLoading) return [];

    const allData = scenarios.flatMap(scenario => {
      const data = getChartData(scenario, activeChart);
      console.log('Chart data for', scenario.name, ':', data);
      return data;
    });

    console.log('Combined chart data:', allData);
    return allData;
  }, [scenarios, activeChart, getChartData, isLoading]);

  const commonAxisProps = (xAxisLabel, yAxisLabel) => ({
    xAxis: {
      label: { value: xAxisLabel, position: 'insideBottom', offset: -10 }
    },
    yAxis: {
      label: { value: yAxisLabel, angle: -90, position: 'insideLeft', offset: -40 }
    },
    legend: {
      layout: "horizontal",
      verticalAlign: "bottom",
      align: "center",
      wrapperStyle: { bottom: 0 } // Move legend closer to the graph
    }
  });

  // Update the cumulativeChartData calculation
  const cumulativeChartData = useMemo(() => {
    const allData = scenarios.flatMap(scenario => {
      let cumulativeRevenue = 0;
      return scenario.data.transactions.map(t => {
        cumulativeRevenue += t.amount;
        return {
          cumulativeRevenue: cumulativeRevenue / 1000000, // Convert to millions
          fee: t.fee,
          name: scenario.name
        };
      });
    });
    
    // Sort the data by cumulativeRevenue to ensure proper ordering
    return allData.sort((a, b) => a.cumulativeRevenue - b.cumulativeRevenue);
  }, [scenarios]);

  const renderChart = () => {
    if (scenarios.length === 0) return null;

    const commonProps = {
      width: "100%",
      height: 500,
      data: combinedChartData,
      margin: { top: 20, right: 30, left: 60, bottom: 60 }, // Adjust bottom margin
    };

    switch (activeChart) {
      case 'period':
        return (
          <ResponsiveContainer width="100%" height={550}> {/* Increase height to accommodate legend */}
            <LineChart {...commonProps}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                {...commonAxisProps('Date', 'Rate').xAxis}
                dataKey="period" 
                type="number"
                scale="time"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(tick) => format(new Date(tick), 'MMM yyyy')}
              />
              <YAxis 
                {...commonAxisProps('Date', 'Rate').yAxis}
                tickFormatter={formatPercentage}
                domain={[0, 'dataMax']}
                allowDecimals={true}
                tickCount={6}
              />
              <Tooltip 
                formatter={(value, name) => [formatPercentage(value), name]}
                labelFormatter={(label) => format(new Date(label), 'MMM dd, yyyy')}
              />
              <Legend {...commonAxisProps().legend} />
              {scenarios.map((scenario, index) => (
                <Line
                  key={scenario.name}
                  type="monotone"
                  dataKey="rate"
                  data={getChartData(scenario, 'period')}
                  name={scenario.name.replace('_detailed_control.json', '')}
                  stroke={chartColors[index % chartColors.length]}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        );
      case 'cumulativeRate':
        return (
          <ResponsiveContainer width="100%" height={550}> {/* Increase height to accommodate legend */}
            <LineChart {...commonProps}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                {...commonAxisProps('Cumulative Revenue (Millions)', 'Rate').xAxis}
                dataKey="cumulativeRevenue" 
                tickFormatter={formatXAxis}
                domain={[0, 'dataMax']}
                type="number"
              />
              <YAxis 
                {...commonAxisProps('Cumulative Revenue (Millions)', 'Rate').yAxis}
                tickFormatter={(value) => `${value.toFixed(2)}%`}
                domain={[0, 'dataMax']}
                allowDecimals={true}
                tickCount={6}
              />
              <Tooltip 
                formatter={(value, name) => [`${value.toFixed(4)}%`, name]}
                labelFormatter={(label) => `$${formatNumberWithCommas(label)}M`}
              />
              <Legend {...commonAxisProps().legend} />
              {scenarios.map((scenario, index) => (
                <Line
                  key={scenario.name}
                  type="monotone"
                  dataKey="rate"
                  data={getChartData(scenario, 'cumulativeRate')}
                  name={scenario.name.replace('_detailed_control.json', '')}
                  stroke={chartColors[index % chartColors.length]}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        );
      case 'cumulativeFee':
        console.log('Rendering cumulativeFee chart with data:', combinedChartData);
        const maxRevenue = Math.max(...combinedChartData.map(d => d.cumulativeRevenue));
        const maxFee = Math.max(...combinedChartData.map(d => d.cumulativeFee));
        console.log('Max revenue:', maxRevenue, 'Max fee:', maxFee);
        return (
          <ResponsiveContainer width="100%" height={550}> {/* Increase height to accommodate legend */}
            <LineChart data={cumulativeChartData} margin={{ top: 20, right: 30, left: 60, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="cumulativeRevenue" 
                label={{ value: 'Cumulative Revenue (Millions)', position: 'insideBottom', offset: -10 }}
                tickFormatter={formatXAxis}
                domain={['dataMin', 'dataMax']}
                type="number"
              />
              <YAxis 
                label={{ value: 'Cerebro Cost', angle: -90, position: 'insideLeft', offset: -40 }}
                tickFormatter={formatYAxis}
                domain={[0, 'dataMax']}
              />
              <Tooltip 
                formatter={(value, name, props) => {
                  // Use the 'fee' value for Cerebro Cost
                  const cerebroCost = props.payload.fee;
                  return [`$${formatNumberWithCommas(cerebroCost)}`, name];
                }}
                labelFormatter={(label) => `${formatXAxis(label)}M Revenue`}
              />
              <Legend wrapperStyle={{ paddingTop: '20px' }}/>
              {scenarios.map((scenario, index) => (
                <Line 
                  key={scenario.name}
                  type="monotone" 
                  dataKey="fee" 
                  data={cumulativeChartData.filter(d => d.name === scenario.name)}
                  name={scenario.name.replace('_detailed_control.json', '')}
                  stroke={chartColors[index % chartColors.length]}
                  dot={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        );
      case 'timeSeries':
        return (
          <ResponsiveContainer width="100%" height={550}> {/* Increase height to accommodate legend */}
            <LineChart {...commonProps}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                {...commonAxisProps('Date', 'Cerebro Cost').xAxis}
                dataKey="date"
                type="number"
                scale="time"
                domain={['dataMin', 'dataMax']}
                tickFormatter={(tick) => {
                  const date = new Date(tick);
                  return formatInTimeZone(date, 'UTC', timeAggregation === 'year' ? 'yyyy' : 'MMM yyyy');
                }}
              />
              <YAxis 
                {...commonAxisProps('Date', 'Cerebro Cost').yAxis}
                tickFormatter={formatYAxis}
                domain={[0, 'dataMax']}
                allowDecimals={true}
                tickCount={6}
              />
              <Tooltip
                formatter={(value, name) => [`$${formatNumberWithCommas(value)}`, name]}
                labelFormatter={(label) => {
                  const date = new Date(label);
                  return formatInTimeZone(date, 'UTC', timeAggregation === 'year' ? 'yyyy' : 'MMMM yyyy');
                }}
              />
              <Legend {...commonAxisProps().legend} />
              {scenarios.map((scenario, index) => (
                <Line
                  key={scenario.name}
                  type="monotone"
                  dataKey="fee"
                  data={getChartData(scenario, 'timeSeries')}
                  name={scenario.name.replace('_detailed_control.json', '')}
                  stroke={chartColors[index % chartColors.length]}
                  dot={{ r: 4 }}
                  activeDot={{ r: 8 }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    console.log('Scenarios updated:', scenarios);
    // Check if there are any async operations here that might be modifying the data
  }, [scenarios]);

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Comparisons</h2>
      
      {/* Scenario upload */}
      <div className="mb-4">
        <label className="bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600 transition-colors cursor-pointer">
          Load Scenarios
          <input
            type="file"
            accept=".json"
            onChange={loadScenarios}
            multiple
            className="hidden"
          />
        </label>
      </div>

      {/* Loaded scenarios list */}
      {scenarios.length > 0 && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Loaded Scenarios:</h3>
          <ul>
            {scenarios.map((scenario, index) => (
              <li key={index} className="flex items-center justify-between mb-2">
                <span>{scenario.name}</span>
                <button
                  onClick={() => removeScenario(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Chart type selector */}
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Select Chart Type:</h3>
        <div className="flex space-x-2">
          <button
            onClick={() => setActiveChart('period')}
            className={`px-3 py-1 rounded ${activeChart === 'period' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Rate by Period
          </button>
          <button
            onClick={() => setActiveChart('cumulativeRate')}
            className={`px-3 py-1 rounded ${activeChart === 'cumulativeRate' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Rate by Revenue
          </button>
          <button
            onClick={() => setActiveChart('cumulativeFee')}
            className={`px-3 py-1 rounded ${activeChart === 'cumulativeFee' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Cerebro Cost by Revenue
          </button>
          <button
            onClick={() => setActiveChart('timeSeries')}
            className={`px-3 py-1 rounded ${activeChart === 'timeSeries' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Cerebro Cost Over Time
          </button>
        </div>
      </div>

      {/* Time aggregation selector (only for time series chart) */}
      {activeChart === 'timeSeries' && (
        <div className="mb-4">
          <label className="mr-2">Aggregate by:</label>
          <select
            value={timeAggregation}
            onChange={(e) => setTimeAggregation(e.target.value)}
            className="border rounded px-2 py-1"
          >
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
        </div>
      )}

      {/* Chart */}
      <div className="mb-4">
        <ResponsiveContainer width="100%" height={500}>
          {isLoading ? (
            <div>Loading scenarios...</div>
          ) : (
            renderChart()
          )}
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default Comparisons;
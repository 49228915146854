import React, { useState } from 'react';

function TierGenerator() {
  const [input, setInput] = useState('');
  const [percentageStep, setPercentageStep] = useState(3);
  const [tiers, setTiers] = useState([]);

  const presetBreakpoints = {
    "v2 - retail": '100000 3\n1000000 2.5\n2000000 2\n3000000 1.5\n10000000 1\n15000000 0.75\n25000000 0.5\n50000000 0.35\n100000000 0.25\n1000000000 0.18\n4000000000 0.1',
    "v19 - custom": '5000000 1\n10000000 0.85\n12500000 0.6\n15000000 0.4\n25000000 0.35\n50000000 0.3\n75000000 0.275\n100000000 0.25\n1000000000 0.15\n5000000000 0.1',
    "v26 - custom": '10000000 1\n15000000 0.85\n25000000 0.3\n45000000 0.25\n75000000 0.235\n100000000 0.225\n200000000 0.2\n300000000 0.175\n500000000 0.15\n1000000000 0.125\n5000000000 0.1'
  };

  const generateTiers = (breakpoints, percentageStep) => {
    let tiers = [];
    
    // Add the first breakpoint
    tiers.push({ max: breakpoints[0][0], rate: breakpoints[0][1] });

    for (let i = 0; i < breakpoints.length - 1; i++) {
      const currentPoint = breakpoints[i];
      const nextPoint = breakpoints[i + 1];

      const currentRevenue = currentPoint[0];
      const nextRevenue = nextPoint[0];
      const currentRate = currentPoint[1];
      const nextRate = nextPoint[1];

      const tierSize = (percentageStep / 100) * nextRevenue;
      const numTiers = Math.ceil((nextRevenue - currentRevenue) / tierSize);
      const rateDecrementPerTier = (currentRate - nextRate) / numTiers;

      for (let j = 1; j <= numTiers; j++) {
        const tierMax = Math.min(currentRevenue + j * tierSize, nextRevenue);
        const tierRate = currentRate - rateDecrementPerTier * j;

        if (tierMax > currentRevenue) {
          tiers.push({ max: tierMax, rate: tierRate });
        }

        if (tierMax === nextRevenue) {
          break;
        }
      }
    }

    // Add the final tier with Infinity and 10% smaller rate
    const lastTier = tiers[tiers.length - 1];
    const finalRate = Math.max(lastTier.rate * 0.9, 0); // Ensure rate doesn't go below 0
    tiers.push({ max: Infinity, rate: finalRate });

    return tiers;
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handlePercentageStepChange = (e) => {
    setPercentageStep(parseFloat(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const lines = input.trim().split('\n');
    const breakpoints = lines.map(line => {
      const [revenue, rate] = line.split(/\s+/).map(Number);
      return [revenue, rate];
    });
    const generatedTiers = generateTiers(breakpoints, percentageStep);
    setTiers(generatedTiers);
  };

  const formatTiersForDisplay = () => {
    return tiers.map(tier => {
      const maxValue = tier.max === Infinity ? 'Infinity' : tier.max.toFixed(0);
      return `${maxValue}\t${tier.rate.toFixed(8)}`;
    }).join('\n');
  };

  const handlePresetChange = (e) => {
    const selectedPreset = e.target.value;
    if (selectedPreset in presetBreakpoints) {
      setInput(presetBreakpoints[selectedPreset]);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Build New Rate Schedule</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="presetBreakpoints" className="block text-sm font-medium text-gray-700 mb-1">
            Presets:
          </label>
          <select
            id="presetBreakpoints"
            onChange={handlePresetChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">Select a preset or enter custom breakpoints</option>
            <option value="v2 - retail">V2 Retail Schedule</option>
            <option value="v19 - custom">V19 Custom Schedule</option>
            <option value="v26 - custom">V26 Custom Schedule</option>
          </select>
        </div>
        <div>
          <label htmlFor="breakpoints" className="block text-sm font-medium text-gray-700 mb-1">
            Breakpoints:
          </label>
          <textarea
            id="breakpoints"
            value={input}
            onChange={handleInputChange}
            placeholder="Enter breakpoints (revenue and rate separated by space, one per line)"
            rows={10}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label htmlFor="percentageStep" className="block text-sm font-medium text-gray-700 mb-1">
            Percentage Step:
          </label>
          <div className="flex items-center">
            <input
              type="number"
              id="percentageStep"
              value={percentageStep}
              onChange={handlePercentageStepChange}
              step="1"
              min="1"
              max="100"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            <span className="ml-2 text-gray-600">%</span>
          </div>
          <p className="mt-2 text-sm text-gray-600">
            The percentage step determines the granularity of the generated tiers. It calculates tier sizes as a percentage of the next breakpoint. A smaller percentage creates more tiers with finer granularity, while a larger percentage creates fewer tiers with coarser granularity. The minimum tier size is the current breakpoint value.
          </p>
        </div>
        <button
          type="submit"
          className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
        >
          Generate Tiers
        </button>
      </form>
      {tiers.length > 0 && (
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-2 text-gray-800">Generated Tiers:</h3>
          <textarea
            readOnly
            value={formatTiersForDisplay()}
            rows={20}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm font-mono text-sm"
          />
        </div>
      )}
    </div>
  );
}

export default TierGenerator;
